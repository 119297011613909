import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootStateTypeExtra } from '@store/types'

import { WhiteLabel } from '@api/white-label'

let initialState = {} as WhiteLabel

const whiteLabelData = localStorage.getItem('whiteLabelApiData')
if (whiteLabelData) {
  initialState = JSON.parse(whiteLabelData)
}

const whiteLabelSlice = createSlice({
  name: 'whiteLabel',
  initialState,
  reducers: {
    setWhiteLabel: (state: WhiteLabel, action: PayloadAction<WhiteLabel>) => {
      localStorage.setItem(
        'whiteLabelApiData',
        JSON.stringify({ ...action.payload })
      )
      return { ...action.payload }
    },
    removeWhiteLabel: () => {
      localStorage.removeItem('whiteLabelApiData')
      return {} as WhiteLabel
    },
  },
})

export const { setWhiteLabel, removeWhiteLabel } = whiteLabelSlice.actions

export const selectWhiteLabel = (state: RootStateTypeExtra) => state.whiteLabel

export default whiteLabelSlice.reducer
