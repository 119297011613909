import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ShortlistState, setShortlists } from '@store/actionSlices/shortlist'
import {
  HouseAndLandPackageOptionType,
  LotShortlistInterface,
  PackageConfigurationInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import { Status } from '@components/data-handler/data-handler'
import { InfoModal } from '@components/modals'
import SlideshowControl from '@components/slideshow-control'

import {
  selectFromResult as selectFromExternalLinkResult,
  useGetExternalLinksByPropertyQuery,
} from '@api/external-links'
import {
  FileInterface,
  selectMatrixDataFromResult,
  selectPackagesFromResult,
  selectPrecinctListFromResult,
  useGetMatrixDataByPrecinctQuery,
  useGetPackagesByIdQuery,
  useGetPrecinctListQuery,
} from '@api/houseAndLand'
import {
  selectFromResult as selectFromPanoramicResult,
  useGetPanoramicQuery,
} from '@api/panoramic'
import {
  selectFromResult as selectFromLotGalleryResult,
  useGetUnitGalleryQuery as useGetLotGalleryQuery,
} from '@api/unit-gallery'

import { formatPrice } from '@utilities/helper'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import {
  BathSvg,
  BedSvg,
  CarSvg,
  ChevronSvg,
  CompassRegularSvg,
  InfoCircleSvg,
  LinkSvg,
  PanoramaSvg,
  PowderSvg,
  StudySvg,
} from '@svg/react'

import { formatAreaNumber } from '@adUtilities/helper'

import LotGallery from './lot-gallery'
import LotPackage from './lot-package'
import LotSkeleton from './lot-skeleton'
import ShortlistButton from './shortlist-button'

interface LotViewProps {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  shortlist: ShortlistState
  packageListTitleWhiteLabel: string
}

const INITIAL_PACKAGE_CONFIGURATION: PackageConfigurationInterface = {
  floorplan: '',
  floorplanOption: '',
  facade: '',
  facadeColor: '',
  internalTheme: '',
  internalColorScheme: '',
}
const INITIAL_LAST_ACTIVE_OPTION = {
  type: '',
  id: '',
}

const mappedPackageOptionType = (type: string) => {
  const types: { [type: string]: HouseAndLandPackageOptionType } = {
    facade: HouseAndLandPackageOptionType.Facade,
    facadeColor: HouseAndLandPackageOptionType.FacadeColor,
    floorplanOption: HouseAndLandPackageOptionType.FloorplanOption,
    internalColorScheme: HouseAndLandPackageOptionType.InternalColorScheme,
    internalTheme: HouseAndLandPackageOptionType.InternalTheme,
  }

  return types[type] || type
}

function LotView({
  projectIdentity,
  session,
  shortlist,
  packageListTitleWhiteLabel,
}: LotViewProps) {
  const history = useHistory()
  const dispatch = useDispatch()
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  const { houseAndLandConfig } = projectIdentity

  const isReset = React.useRef(false)

  const [updatedPackageOnEntry, setUpdatedPackageOnEntry] =
    React.useState<boolean>(false)
  const [activePrecinctId, setActivePrecinctId] = React.useState<string>('')
  const [activeStageId, setActiveStageId] = React.useState<string>('')
  const [activeLotId, setActiveLotId] = React.useState<string>('')
  const [lotInfoPopup, setLotInfoPopup] = React.useState(false)
  const [externalLinkPopup, setExternalLinkPopup] = React.useState(false)

  const [activePackageConfiguration, setActivePackageConfiguration] =
    React.useState<PackageConfigurationInterface>({
      ...INITIAL_PACKAGE_CONFIGURATION,
    })
  const [lastActiveOption, setLastActiveOption] = React.useState<{
    type: string
    id: string
  }>(INITIAL_LAST_ACTIVE_OPTION)

  const [shouldShowSlideshowBtn, setShouldShowSlideShowBtn] =
    React.useState<boolean>(false)
  const [disableSlideShowButton, setDisableSlideShowButton] =
    React.useState<boolean>(false)

  const matrixPayload = useGetMatrixDataByPrecinctQuery(
    {
      projectName: projectIdentity.projectId,
      precinctIdOrLabel: activePrecinctId,
      lots: true,
    },
    {
      selectFromResult: selectMatrixDataFromResult,
      skip: !activePrecinctId,
    }
  )

  const activeLotData = matrixPayload?.matrixData?.stages?.[
    activeStageId
  ]?.lots?.find((lot) => lot.name === activeLotId)
  const packageList =
    matrixPayload?.matrixData?.packages?.filter((item) =>
      item.lots?.includes(activeLotData?.id || '')
    ) || []
  const noPackage =
    packageList?.length === 0 && matrixPayload.status === 'fulfilled'

  const packagePayload = useGetPackagesByIdQuery(
    {
      projectName: projectIdentity.projectId,
      packageIds: packageList?.map((item) => item.id).join(',') || '',
    },
    {
      selectFromResult: selectPackagesFromResult,
      skip: !activeStageId || !activeLotId || packageList?.length === 0,
    }
  )

  const { isShortlisted, shortlistedConfiguration } = React.useMemo(() => {
    const matchedShortlist = shortlist.shortlists.find(
      (item) => item.propertyId === activeLotData?.id
    ) as LotShortlistInterface | undefined
    return {
      isShortlisted: !!matchedShortlist,
      shortlistedConfiguration: matchedShortlist?.configuration,
    }
  }, [shortlist.shortlists, activeLotData?.id])

  React.useEffect(() => {
    if (isShortlisted) {
      setUpdatedPackageOnEntry(true)
      return
    }
    if (
      !activePackageConfiguration.floorplan &&
      packagePayload?.packages?.length > 0 &&
      !updatedPackageOnEntry
    ) {
      firebaseControlQuery.update({
        [`houseAndLand.activePackageId`]: packagePayload?.packages?.[0]?.id,
      })
      setUpdatedPackageOnEntry(true)
    }
  }, [
    isShortlisted,
    activePackageConfiguration.floorplan,
    packagePayload,
    updatedPackageOnEntry,
  ])

  const activePackageData = React.useMemo(
    () =>
      packagePayload?.packages.find(
        (pkg) => pkg.id === activePackageConfiguration.floorplan
      ),
    [activePackageConfiguration.floorplan, packagePayload]
  )

  const precinctsPayload = useGetPrecinctListQuery(
    { projectName: projectIdentity.projectId },
    {
      selectFromResult: selectPrecinctListFromResult,
    }
  )

  const lotGalleryPayload = useGetLotGalleryQuery(
    {
      projectName: projectIdentity.projectId,
      unitId: activeLotData?.name || '',
    },
    {
      selectFromResult: selectFromLotGalleryResult,
      skip:
        ((!noPackage || matrixPayload?.status !== Status.FULFILLED) &&
          (noPackage || packagePayload?.status !== Status.FULFILLED)) ||
        !activeLotData?.name,
    }
  )
  const { unitGalleryData: lotGalleryData } = lotGalleryPayload

  const externalLinksPayload = useGetExternalLinksByPropertyQuery(
    {
      projectName: projectIdentity.projectId,
      propertyId: activeLotData?.id || '',
    },
    {
      skip: !activeLotData?.id,
      selectFromResult: selectFromExternalLinkResult,
    }
  )

  const { panoramicData } = useGetPanoramicQuery(
    {
      projectName: projectIdentity.projectId,
      panoramaGroup: activeLotData?.name || '',
    },
    { selectFromResult: selectFromPanoramicResult, skip: !activeLotData?.name }
  )

  const handleChangePackageConfiguration = async (
    propertyType: keyof PackageConfigurationInterface,
    value: string
  ) => {
    const type = mappedPackageOptionType(propertyType) as string

    await firebaseControlQuery.update({
      [`houseAndLand.lastActiveOption.type`]: type,
      [`houseAndLand.lastActiveOption.id`]: value,
    })
    switch (type) {
      case 'floorplan': {
        await firebaseControlQuery.update({
          [`houseAndLand.activePackageId`]: value,
          [`houseAndLand.activeFloorplanOptionId`]: '',
          [`houseAndLand.activeFacadeId`]: '',
          [`houseAndLand.activeFacadeColorId`]: '',
          [`houseAndLand.activeInternalThemeId`]: '',
          [`houseAndLand.activeInternalColorSchemeId`]: '',
        })

        break
      }
      case HouseAndLandPackageOptionType.FloorplanOption: {
        await firebaseControlQuery.update({
          [`houseAndLand.activeFloorplanOptionId`]: value,
        })

        break
      }
      case HouseAndLandPackageOptionType.Facade: {
        await firebaseControlQuery.update({
          [`houseAndLand.activeFacadeId`]: value,
          [`houseAndLand.activeFacadeColorId`]: '',
        })

        break
      }
      case HouseAndLandPackageOptionType.FacadeColor: {
        await firebaseControlQuery.update({
          [`houseAndLand.activeFacadeColorId`]: value,
        })

        break
      }
      case HouseAndLandPackageOptionType.InternalTheme: {
        await firebaseControlQuery.update({
          [`houseAndLand.activeInternalThemeId`]: value,
          [`houseAndLand.activeInternalColorSchemeId`]: '',
        })

        break
      }
      case HouseAndLandPackageOptionType.InternalColorScheme: {
        await firebaseControlQuery.update({
          [`houseAndLand.activeInternalColorSchemeId`]: value,
        })

        break
      }
      default:
        break
    }
  }

  const handleInfoPopup = async () => {
    await firebaseControlQuery.update({
      [`houseAndLand.lotInfoPopup`]: !lotInfoPopup,
    })
  }

  const handleExternalLinkPopup = async () => {
    await firebaseControlQuery.update({
      [`houseAndLand.externalLinkPopup`]: !externalLinkPopup,
    })
  }

  const handleResetStates = async (newPathname?: string) => {
    isReset.current = true
    await firebaseControlQuery.update({
      [`houseAndLand.lotInfoPopup`]: false,
      [`houseAndLand.externalLinkPopup`]: false,
      [`houseAndLand.isDataLoaded`]: false,
      ...(newPathname !== '/panoramic' && {
        [`houseAndLand.activeLotId`]: '',
        [`houseAndLand.activePackageId`]: '',
        [`houseAndLand.activeFloorplanOptionId`]: '',
        [`houseAndLand.activeFacadeId`]: '',
        [`houseAndLand.activeFacadeColorId`]: '',
        [`houseAndLand.activeInternalThemeId`]: '',
        [`houseAndLand.activeInternalColorSchemeId`]: '',
        [`houseAndLand.lastActiveOption.type`]: '',
        [`houseAndLand.lastActiveOption.id`]: '',
      }),
    })
  }

  const handleBackButtonPress = () => {
    history.push('stages')
  }

  const handleConfigurationPropertyExpand = async ({
    propertyType,
    expanded,
  }: {
    propertyType: keyof PackageConfigurationInterface
    expanded: boolean
  }) => {
    const expandedId = activePackageConfiguration?.[propertyType] || ''
    if (expanded && expandedId) {
      await firebaseControlQuery.update({
        [`houseAndLand.lastActiveOption.type`]: mappedPackageOptionType(
          propertyType
        ) as string,
        [`houseAndLand.lastActiveOption.id`]: expandedId,
      })
    }
  }

  const getPackageOptionDetailById = (
    type: HouseAndLandPackageOptionType,
    id: string
  ): { images: FileInterface[]; price: number } => {
    if (!activePackageData) return { images: [], price: 0 }

    const {
      FloorplanOption,
      Facade,
      FacadeColor,
      InternalTheme,
      InternalColorScheme,
    } = HouseAndLandPackageOptionType

    if (type === FloorplanOption) {
      const optionData = activePackageData[type]?.find((item) => item.id === id)
      return {
        images: optionData?.images || [],
        price: optionData?.price || 0,
      }
    }

    if (type === Facade) {
      const optionData = activePackageData[type]?.find((item) => item.id === id)
      return {
        images: optionData?.images || [],
        price: optionData?.price || 0,
      }
    }

    if (type === FacadeColor) {
      let images: FileInterface[] = []
      let price = 0
      activePackageData[Facade]?.forEach((item) => {
        item.facadeColors.forEach((facadeColor) => {
          if (facadeColor.id === id) {
            images = images.concat(facadeColor.images)
            price = facadeColor.price || 0
          }
        })
      })

      return { images, price }
    }

    if (type === InternalTheme) {
      const optionData = activePackageData[type]?.find((item) => item.id === id)
      return {
        images: optionData?.images || [],
        price: optionData?.price || 0,
      }
    }

    if (type === InternalColorScheme) {
      let images: FileInterface[] = []
      let price = 0
      activePackageData[InternalTheme]?.forEach((item) => {
        item.internalColorSchemes.forEach((facadeColor) => {
          if (facadeColor.id === id) {
            images = images.concat(facadeColor.images)
            price = facadeColor.price || 0
          }
        })
      })
      return { images, price }
    }

    return { images: [], price: 0 }
  }

  React.useEffect(() => {
    if (session) {
      const {
        connected,
        houseAndLand: {
          activeLotId: activeLotIdFirebase,
          activeStageId: activeStageIdFirebase,
          activePrecinctId: activePrecinctIdFirebase,
          activePackageId: activePackageIdFirebase,
          activeFloorplanOptionId: activeFloorplanOptionIdFirebase,
          activeFacadeId: activeFacadeIdFirebase,
          activeFacadeColorId: activeFacadeColorIdFirebase,
          activeInternalThemeId: activeInternalThemeIdFirebase,
          activeInternalColorSchemeId: activeInternalColorSchemeIdFirebase,
          lastActiveOption: {
            type: lastActiveOptionTypeFirebase,
            id: lastActiveOptionIdFirebase,
          },
          lotInfoPopup: lotInfoPopupFirebase,
          externalLinkPopup: externalLinkPopupFirebase,
        },
        lotGallery: {
          playerControl: { playerState: firebasePlayerState },
        },
      } = session

      if (connected) {
        if (!activeLotIdFirebase && !isReset.current) {
          history.push('stages')
          firebaseControlQuery.updateRoute('stages')
        }

        setActivePrecinctId(activePrecinctIdFirebase)
        setActiveStageId(activeStageIdFirebase)
        setActiveLotId(activeLotIdFirebase)
        setLotInfoPopup(lotInfoPopupFirebase)
        setExternalLinkPopup(externalLinkPopupFirebase)
        setDisableSlideShowButton(firebasePlayerState === 'play')

        setActivePackageConfiguration({
          floorplan: activePackageIdFirebase,
          floorplanOption: activeFloorplanOptionIdFirebase,
          facade: activeFacadeIdFirebase,
          facadeColor: activeFacadeColorIdFirebase,
          internalTheme: activeInternalThemeIdFirebase,
          internalColorScheme: activeInternalColorSchemeIdFirebase,
        })
        setLastActiveOption({
          type: lastActiveOptionTypeFirebase,
          id: lastActiveOptionIdFirebase,
        })
        if (activePackageIdFirebase) {
          setUpdatedPackageOnEntry(true)
        }
      }
    }
  }, [session?.connected, session?.houseAndLand])

  React.useEffect(() => {
    if (!(session?.connected && isShortlisted)) {
      return
    }

    firebaseControlQuery.update({
      [`houseAndLand.activePackageId`]:
        shortlistedConfiguration?.floorplan || '',
      [`houseAndLand.activeFloorplanOptionId`]:
        shortlistedConfiguration?.floorplanOption || '',
      [`houseAndLand.activeFacadeId`]: shortlistedConfiguration?.facade || '',
      [`houseAndLand.activeFacadeColorId`]:
        shortlistedConfiguration?.facadeColor || '',
      [`houseAndLand.activeInternalThemeId`]:
        shortlistedConfiguration?.internalTheme || '',
      [`houseAndLand.activeInternalColorSchemeId`]:
        shortlistedConfiguration?.internalColorScheme || '',
    })
  }, [isShortlisted, session?.connected])

  React.useEffect(() => {
    const removeUnitOnRouteChange = (location: Location) => {
      const { pathname } = location
      handleResetStates(pathname).catch((error) => console.error(error))
    }

    const routeChangeUnsubscribe = history.listen(removeUnitOnRouteChange)

    return () => {
      routeChangeUnsubscribe()
    }
  }, [history])

  React.useEffect(() => {
    if (!session?.connected) {
      return
    }
    dispatch(setShortlists(session?.shortlist?.properties || []))
  }, [session?.connected])

  const totalPrice = React.useMemo((): number => {
    let tempPrice = activeLotData?.price || 0

    if (
      Object.entries(activePackageData || {}).length === 0 ||
      !activePackageConfiguration
    ) {
      return tempPrice
    }

    if (activePackageData?.housePrice) {
      tempPrice += activePackageData.housePrice ?? 0
    }

    const {
      FloorplanOption,
      Facade,
      FacadeColor,
      InternalTheme,
      InternalColorScheme,
    } = HouseAndLandPackageOptionType

    if (activePackageConfiguration.floorplanOption) {
      tempPrice +=
        getPackageOptionDetailById(
          FloorplanOption,
          activePackageConfiguration.floorplanOption
        ).price || 0
    }

    if (activePackageConfiguration.facade) {
      tempPrice +=
        getPackageOptionDetailById(Facade, activePackageConfiguration.facade)
          .price || 0
    }

    if (activePackageConfiguration.facadeColor) {
      tempPrice +=
        getPackageOptionDetailById(
          FacadeColor,
          activePackageConfiguration.facadeColor
        ).price || 0
    }

    if (activePackageConfiguration.internalTheme) {
      tempPrice +=
        getPackageOptionDetailById(
          InternalTheme,
          activePackageConfiguration.internalTheme
        ).price || 0
    }

    if (activePackageConfiguration.internalColorScheme) {
      tempPrice +=
        getPackageOptionDetailById(
          InternalColorScheme,
          activePackageConfiguration.internalColorScheme
        ).price || 0
    }

    return tempPrice
  }, [activeLotData, activePackageData, activePackageConfiguration])

  const packageImages: FileInterface[] = React.useMemo(
    () =>
      getPackageOptionDetailById(
        lastActiveOption.type as HouseAndLandPackageOptionType,
        lastActiveOption.id
      ).images,
    [lastActiveOption, getPackageOptionDetailById]
  )

  const dataHandlerPayload = React.useMemo(() => {
    if (lotGalleryPayload.status === Status.FULFILLED || noPackage) {
      firebaseControlQuery.update({
        [`houseAndLand.isDataLoaded`]: true,
      })
    }

    if (noPackage) {
      return {
        ...lotGalleryPayload,
        data:
          lotGalleryPayload.status === Status.FULFILLED
            ? matrixPayload.matrixData?.packages
            : [],
      }
    }

    if (packagePayload.status === Status.FULFILLED) {
      return {
        ...lotGalleryPayload,
        data:
          lotGalleryPayload.status === Status.FULFILLED
            ? packagePayload.packages
            : [],
      }
    }

    return {
      ...packagePayload,
      data: packagePayload.packages,
    }
  }, [
    packagePayload.status,
    lotGalleryPayload.status,
    noPackage,
    matrixPayload.status,
  ])

  const isPriceVisible =
    projectIdentity.showPrice &&
    !(
      activeLotData?.status !== projectIdentity?.statusLabels?.available &&
      projectIdentity.hideSoldPrice
    )

  return (
    <Container>
      <DataHandler
        payload={{
          ...dataHandlerPayload,
        }}
        skeletonFrame={<LotSkeleton />}
      >
        <div className="h-full w-full px-4">
          <div className="h-top-bar w-full py-2 text-neutralColour">
            <div className="flex h-[40%] items-start justify-between text-default font-normal">
              <button
                onClick={() => {
                  handleBackButtonPress()
                }}
                type="button"
                className="relative inline-flex items-center"
              >
                <ChevronSvg className="absolute -left-2.5 h-8 w-8" />

                <span className="ml-6">Stage {activeStageId}</span>
              </button>

              {panoramicData?.length > 0 && (
                <button
                  onClick={() => {
                    firebaseControlQuery.updateRoute('panoramic')
                    history.push('panoramic?prevUrl=lot-view')
                  }}
                  type="button"
                  className="inline-flex items-center"
                >
                  <span className="mr-2">Panoramic view</span>
                  <PanoramaSvg className="h-8 w-8" />
                </button>
              )}
            </div>

            <div className="flex h-[60%] w-full items-center justify-between text-neutralColour">
              <p className="text-title font-medium">
                Lot {activeLotData?.name}
              </p>

              {shouldShowSlideshowBtn && (
                <SlideshowControl
                  galleryName="lotGallery"
                  isDisabled={disableSlideShowButton}
                />
              )}
            </div>
          </div>

          <div className="no-scrollbar relative flex h-page-body w-full gap-[15px] overflow-hidden pb-4">
            <div className="flex flex-1 flex-col gap-[15px]">
              <div className="min-h-[250px] overflow-hidden rounded-md ipad-mini-land:min-h-[300px]">
                <LotGallery
                  lotGalleryData={lotGalleryData}
                  lotGalleryControlSession={session?.lotGallery}
                  lastActiveOptionType={lastActiveOption.type}
                  lastActiveOptionId={lastActiveOption.id}
                  setShouldShowSlideShowBtn={setShouldShowSlideShowBtn}
                  activePackageData={activePackageData}
                  activeLotData={activeLotData}
                  packageImages={packageImages}
                />
              </div>

              <div className="flex flex-1 flex-col justify-between rounded-lg bg-white/10 py-4 text-white ipad-mini-land:py-5">
                <div className="no-scrollbar flex-1 basis-0 overflow-y-scroll">
                  {precinctsPayload?.precinctList?.length > 1 && (
                    <p className="px-4 text-base font-bold ipad-mini-land:px-6">
                      {activePrecinctId}
                    </p>
                  )}

                  <div className="px-4 ipad-mini-land:px-6">
                    <p className="text-[25px] font-bold">
                      {activePackageData?.name || `Lot ${activeLotData?.name}`}
                    </p>

                    {Number(totalPrice) > 0 && isPriceVisible && (
                      <p className="text-[25px] font-medium">
                        {formatPrice(totalPrice)}
                      </p>
                    )}
                  </div>

                  <div className="my-4 flex flex-1 flex-wrap justify-between gap-x-[0.6rem] gap-y-1 bg-black/25 px-4 py-2 empty:hidden ipad-mini-land:px-6 ipad-mini-land:py-4">
                    {(activePackageData?.configuration?.bed || 0) > 0 && (
                      <div className="inline-flex items-center text-base font-medium ipad-mini-land:text-lg">
                        <BedSvg className="mr-1.5 h-5 w-5 ipad-mini-land:h-6 ipad-mini-land:w-6" />
                        {activePackageData?.configuration?.bed}
                      </div>
                    )}
                    {(activePackageData?.configuration?.study || 0) > 0 && (
                      <div className="inline-flex items-center text-base font-medium ipad-mini-land:text-lg">
                        <StudySvg className="mr-1.5 h-5 w-5 ipad-mini-land:h-6 ipad-mini-land:w-6" />
                        {activePackageData?.configuration?.study}
                      </div>
                    )}
                    {(activePackageData?.configuration?.bath || 0) > 0 && (
                      <div className="inline-flex items-center text-base font-medium ipad-mini-land:text-lg">
                        <BathSvg className="mr-1.5 h-5 w-5 ipad-mini-land:h-6 ipad-mini-land:w-6" />
                        {activePackageData?.configuration?.bath}
                      </div>
                    )}
                    {(activePackageData?.configuration?.powderRoom || 0) >
                      0 && (
                      <div className="inline-flex items-center text-base font-medium ipad-mini-land:text-lg">
                        <PowderSvg className="mr-1.5 h-5 w-5 ipad-mini-land:h-6 ipad-mini-land:w-6" />
                        {activePackageData?.configuration?.powderRoom}
                      </div>
                    )}
                    {(activePackageData?.configuration?.car || 0) > 0 && (
                      <div className="inline-flex items-center text-base font-medium ipad-mini-land:text-lg">
                        <CarSvg className="mr-1.5 h-5 w-5 ipad-mini-land:h-6 ipad-mini-land:w-6" />
                        {activePackageData?.configuration?.car}
                      </div>
                    )}
                    {activeLotData?.aspect && (
                      <>
                        <div className="border-l border-white first:hidden" />
                        <div className="inline-flex items-center text-base font-medium only:self-start ipad-mini-land:text-lg">
                          <CompassRegularSvg
                            className="mr-1.5 h-5 w-5 ipad-mini-land:h-6 ipad-mini-land:w-6"
                            fill="white"
                            size="m"
                          />
                          {activeLotData?.aspect}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="grid grid-cols-2 gap-x-2 gap-y-1 px-4 pt-2 text-xl ipad-mini-land:px-6">
                    {!!activeLotData?.area && (
                      <p>
                        Lot Area:{' '}
                        <span className="font-semibold">
                          {formatAreaNumber(activeLotData?.area)} sqm
                        </span>
                      </p>
                    )}
                    {!!activeLotData?.frontage && (
                      <p>
                        Lot Frontage:{' '}
                        <span className="font-semibold">
                          {formatAreaNumber(activeLotData?.frontage)} sqm
                        </span>
                      </p>
                    )}
                    {!!activePackageData?.houseSize && (
                      <p>
                        House Area:{' '}
                        <span className="font-semibold">
                          {formatAreaNumber(activePackageData?.houseSize)} sqm
                        </span>
                      </p>
                    )}
                    {!!activePackageData?.storey && (
                      <p>
                        Storey:{' '}
                        <span className="font-semibold">
                          {houseAndLandConfig?.storey?.find(
                            (item) =>
                              Number(item.value) ===
                              Number(activePackageData?.storey)
                          )?.label || activePackageData?.storey}
                        </span>
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-4 px-4 pt-4 ipad-mini-land:px-6 ipad-mini-land:pt-6">
                  {externalLinksPayload?.externalLinks?.length > 0 && (
                    <button
                      className="flex w-full items-center justify-center gap-4 rounded-md border-2 border-white px-4 py-2 text-base font-bold text-white ipad-mini-land:py-4 ipad-mini-land:text-lg"
                      type="button"
                      onClick={handleExternalLinkPopup}
                    >
                      {externalLinkPopup
                        ? 'HIDE EXTERNAL LINK'
                        : 'LOAD EXTERNAL LINK'}
                      <LinkSvg size="m" className="h-6 w-6" />
                    </button>
                  )}

                  <button
                    className="flex w-full items-center justify-center gap-4 rounded-lg border-2 border-white p-4 text-base font-bold text-white ipad-mini-land:py-4 ipad-mini-land:text-lg"
                    type="button"
                    onClick={handleInfoPopup}
                  >
                    {lotInfoPopup ? 'HIDE INFORMATION' : 'SHOW INFORMATION'}
                    <InfoCircleSvg size="m" className="h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>

            <div className="relative flex flex-1 flex-col justify-between overflow-hidden rounded-lg bg-secondaryColour">
              <LotPackage
                packages={packagePayload?.packages}
                values={activePackageConfiguration}
                onChange={handleChangePackageConfiguration}
                onExpandProperty={handleConfigurationPropertyExpand}
                packageListTitleWhiteLabel={packageListTitleWhiteLabel || ''}
              />

              <div className="absolute bottom-4 w-full px-4 ipad-mini-land:bottom-5 ipad-mini-land:px-5">
                <ShortlistButton
                  activeLotData={activeLotData}
                  activePrecinctId={activePrecinctId}
                  activeStageId={activeStageId}
                  activePackageConfiguration={activePackageConfiguration}
                />
              </div>
            </div>
          </div>
        </div>
      </DataHandler>

      <InfoModal
        isVisible={externalLinkPopup}
        toggleModal={handleExternalLinkPopup}
        title="External link selected"
        message="An external site has been launched. Please use the main Showcase to continue navigating."
      />
    </Container>
  )
}

export default connect(
  ({
    projectIdentity,
    firestore: { session },
    shortlist,
    whiteLabel: {
      lotView: { packageListTitle: packageListTitleWhiteLabel },
    },
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    shortlist,
    packageListTitleWhiteLabel,
  })
)(LotView)
