import React from 'react'
import { connect, useDispatch } from 'react-redux'

import { removeShortlist } from '@store/actionSlices/shortlist'
import type {
  GalleryItemInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import CarouselHandler from '@components/carousel-handler'
import Container from '@components/container'
import DataHandler from '@components/data-handler'
import Navigation from '@components/navigation'
import SlideshowControl from '@components/slideshow-control'

import { selectFromResult, useGetVisionQuery } from '@api/vision'

import { SessionMap } from '@firebaseUtil/types'

import VisionSkeleton from './vision-skeleton'

export interface VisionProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
}

function Vision({ projectIdentity, session }: VisionProps) {
  const dispatch = useDispatch()
  const carouselName = 'vision'

  const [isSlideShowPlaying, setSlideShowPlayingStatus] = React.useState(false)

  const visionPayload = useGetVisionQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult }
  )

  React.useEffect(() => {
    if (session) {
      const { connected } = session
      if (!connected) {
        dispatch(removeShortlist())
      }
    }
  }, [session])

  React.useEffect(() => {
    if (session) {
      const {
        vision: {
          galleryControl: { isPlaying: isPlayingFirebase },
        },
      } = session
      setSlideShowPlayingStatus(isPlayingFirebase)
    }
  }, [session])

  const visionBackground: GalleryItemInterface = React.useMemo(() => {
    const {
      theme: { remoteVisionBackground },
    } = projectIdentity

    if (remoteVisionBackground.type === 'image') {
      return {
        id: visionPayload.gallery?.[0]?.id,
        imageSource: remoteVisionBackground.src ?? '',
        assetType: 'new',
      }
    }

    if (remoteVisionBackground.type === 'color') {
      return {
        id: visionPayload.gallery?.[0]?.id,
        imageSource: remoteVisionBackground.color ?? '',
        config: {
          isTypeColor: true,
        },
      }
    }

    return visionPayload.gallery?.[0]
  }, [visionPayload, projectIdentity])

  return (
    <Container hasBottomNavigation>
      <Navigation type="bottom-navigation">
        <DataHandler
          payload={{
            ...visionPayload,
            data: visionPayload.gallery,
          }}
          skeletonFrame={<VisionSkeleton />}
        >
          <div className="absolute h-full w-full overflow-hidden">
            <CarouselHandler
              carouselName={carouselName}
              carousel={{
                label: '',
                items: visionBackground ? [visionBackground] : [],
              }}
              carouselSession={session?.vision}
              roundedControl
              hideControls={isSlideShowPlaying}
              disableOverlay
            />
          </div>
          {visionPayload.gallery.length > 1 ? (
            <SlideshowControl
              galleryName="vision"
              className="z-10 mt-auto flex-row-reverse self-center rounded-t-lg bg-[#00000066] px-6 py-4 text-white shadow-[0_-5px_5px_0px_rgba(0,0,0,0.20)_inset] backdrop-blur"
            />
          ) : null}
        </DataHandler>
      </Navigation>
    </Container>
  )
}

export default connect(
  ({
    firestore: { session },
    projectIdentity,
    shortlist,
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    shortlist,
  })
)(Vision)
