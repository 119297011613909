import React from 'react'

import { PackageConfigurationInterface } from '@store/types'

import { PackageInterface } from '@api/houseAndLand'

import SelectProperty from './select-property/select-property'

export interface LotPackageProps {
  packages: PackageInterface[]
  values: PackageConfigurationInterface
  onChange: (
    propertyType: keyof PackageConfigurationInterface,
    value: string
  ) => void
  onExpandProperty?: (data: {
    propertyType: keyof PackageConfigurationInterface
    expanded: boolean
  }) => void
  packageListTitleWhiteLabel?: string
}

const SCROLL_THRESHOLD = 20

function LotPackage({
  packages,
  values,
  onChange,
  onExpandProperty,
  packageListTitleWhiteLabel,
}: LotPackageProps) {
  const panelContainerRef = React.useRef<HTMLDivElement>(null)
  const [expandedProperty, setExpandedProperty] = React.useState<
    keyof PackageConfigurationInterface | undefined
  >('floorplan')

  const activeFloorplan = React.useMemo(
    () => packages.find((pkg) => pkg.id === values.floorplan),
    [packages, values.floorplan]
  )

  const activeFacade = React.useMemo(
    () =>
      activeFloorplan?.facades?.find((facade) => facade.id === values.facade),
    [activeFloorplan, values.facade]
  )

  const activeInternalTheme = React.useMemo(
    () =>
      activeFloorplan?.internalThemes?.find(
        (internalTheme) => internalTheme.id === values.internalTheme
      ),
    [activeFloorplan, values.internalTheme]
  )

  const valuesKey = React.useMemo(() => JSON.stringify(values), [values])

  const handleClick =
    (propertyType: keyof PackageConfigurationInterface) => () => {
      setExpandedProperty(
        expandedProperty === propertyType ? undefined : propertyType
      )
      onExpandProperty?.({
        propertyType,
        expanded: expandedProperty !== propertyType,
      })
    }

  const handleChange =
    (propertyType: keyof PackageConfigurationInterface) => (value: string) => {
      onChange(propertyType, value)
    }

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const activeElement = document.querySelector(
        '.active-property'
      ) as HTMLDivElement

      if (activeElement && panelContainerRef?.current) {
        const offsetTop =
          activeElement.offsetTop -
          panelContainerRef.current.offsetTop -
          SCROLL_THRESHOLD

        panelContainerRef.current.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [expandedProperty])

  return (
    <div
      ref={panelContainerRef}
      className="custom-scrollbar flex-1 overflow-y-auto pb-[80px] ipad-mini-land:pb-[84px]"
    >
      <div
        className="counter-list flex flex-col gap-4 ipad-mini-land:gap-6"
        key={valuesKey}
      >
        {packages.length > 0 && (
          <SelectProperty
            label={packageListTitleWhiteLabel || 'FLOORPLAN'}
            options={packages}
            value={values?.floorplan || ''}
            onChange={handleChange('floorplan')}
            expandOptions={expandedProperty === 'floorplan'}
            onClick={handleClick('floorplan')}
            canDeselect
          />
        )}
        {activeFloorplan?.floorplanOptions?.length ? (
          <SelectProperty
            key={`floorplanOption__${values?.floorplan}`}
            label="FLOORPLAN OPTION"
            options={activeFloorplan?.floorplanOptions || []}
            value={values?.floorplanOption || ''}
            onChange={handleChange('floorplanOption')}
            expandOptions={expandedProperty === 'floorplanOption'}
            onClick={handleClick('floorplanOption')}
            canDeselect
          />
        ) : null}
        {activeFloorplan?.facades?.length ? (
          <SelectProperty
            key={`facade__${values?.floorplan}`}
            label="FACADE"
            options={activeFloorplan?.facades || []}
            value={values?.facade || ''}
            onChange={handleChange('facade')}
            expandOptions={expandedProperty === 'facade'}
            onClick={handleClick('facade')}
            canDeselect
            showAsThumbnail
          />
        ) : null}
        {activeFacade?.facadeColors?.length ? (
          <SelectProperty
            key={`facadeColor__${activeFacade?.id}`}
            label="FACADE COLOUR"
            options={activeFacade?.facadeColors || []}
            value={values?.facadeColor || ''}
            onChange={handleChange('facadeColor')}
            expandOptions={expandedProperty === 'facadeColor'}
            onClick={handleClick('facadeColor')}
            canDeselect
            showAsThumbnail
          />
        ) : null}
        {activeFloorplan?.internalThemes?.length ? (
          <SelectProperty
            key={`internalTheme__${values?.floorplan}`}
            label="INTERNAL THEME"
            options={activeFloorplan?.internalThemes || []}
            value={values?.internalTheme || ''}
            onChange={handleChange('internalTheme')}
            expandOptions={expandedProperty === 'internalTheme'}
            onClick={handleClick('internalTheme')}
            canDeselect
          />
        ) : null}
        {activeInternalTheme?.internalColorSchemes?.length ? (
          <SelectProperty
            key={`internalColorScheme__${activeInternalTheme?.id}`}
            label="INTERNAL COLOUR SCHEME"
            options={activeInternalTheme?.internalColorSchemes || []}
            value={values?.internalColorScheme || ''}
            onChange={handleChange('internalColorScheme')}
            expandOptions={expandedProperty === 'internalColorScheme'}
            onClick={handleClick('internalColorScheme')}
            canDeselect
          />
        ) : null}
      </div>
    </div>
  )
}

export default LotPackage
