import React from 'react'

import { PauseSvg, PlaySvg } from '@svg/react'

interface SlideshowControlProps {
  slideshowState: boolean
  setSlideshowState: (arg: boolean) => void
}

function SlideshowControl({
  slideshowState,
  setSlideshowState,
}: SlideshowControlProps) {
  const handleClick = () => setSlideshowState(!slideshowState)

  return (
    <button
      type="button"
      className="inline-flex items-center text-xl text-secondaryColour"
      onClick={handleClick}
    >
      <span className="mr-2">Start slideshow</span>
      {slideshowState ? (
        <PauseSvg className="h-5 w-5" />
      ) : (
        <PlaySvg className="h-5 w-5" />
      )}
    </button>
  )
}
export default SlideshowControl
