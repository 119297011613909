import React from 'react'
import { connect } from 'react-redux'

import {
  GalleryControlInterface,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import { PlaySvg, StopSvg } from '@svg/react'

interface ComponentPropsInterface {
  className?: string
  galleryName: 'vision' | 'design' | 'unitGallery' | 'lotGallery'
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
  isDisabled?: boolean
}

function SlideshowControl({
  className = '',
  galleryName,
  session,
  projectIdentity,
  isDisabled = false,
}: ComponentPropsInterface) {
  const [localGalleryControl, setLocalGalleryControl] = React.useState<
    Pick<GalleryControlInterface, 'transitionTime' | 'transitionType'>
  >({
    transitionTime: 7000,
    transitionType: 'slide',
  })

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  React.useEffect(() => {
    const { gallerySettings } = projectIdentity

    if (gallerySettings) {
      const {
        slideShow: { type, intervalInSeconds },
      } = gallerySettings

      setLocalGalleryControl({
        transitionTime: intervalInSeconds * 1000,
        transitionType: type,
      })
    }
  }, [projectIdentity])

  const [isSlideShowPlaying, setSlideShowPlayingState] = React.useState(false)

  const handleClick = () => {
    firebaseControlQuery.update({
      [`${galleryName}.galleryControl.transitionTime`]:
        localGalleryControl.transitionTime,
      [`${galleryName}.galleryControl.transitionType`]:
        localGalleryControl.transitionType,
      [`${galleryName}.galleryControl.isPlaying`]: !isSlideShowPlaying,
    })
  }

  React.useEffect(() => {
    if (session) {
      const {
        [galleryName]: {
          galleryControl: { isPlaying: slideShowPlayingStateFirebase },
        },
      } = session

      setSlideShowPlayingState(slideShowPlayingStateFirebase)
    }
  }, [session])

  return (
    <button
      type="button"
      disabled={isDisabled}
      onClick={handleClick}
      className={`inline-flex items-center gap-2.5 text-default ${className} ${
        isDisabled
          ? 'cursor-not-allowed opacity-50'
          : 'cursor-default opacity-100'
      }`}
    >
      <span>{isSlideShowPlaying ? 'Stop' : 'Start'} slideshow</span>{' '}
      {isSlideShowPlaying ? <StopSvg /> : <PlaySvg />}
    </button>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(SlideshowControl)
