import { UseQueryStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks.d'
import { createApi } from '@reduxjs/toolkit/query/react'

import baseQueryInterceptor from '@api/base-query-interceptor'
import { ApiResponse } from '@api/types/api-response.type'

export interface AreaViewWhiteLabel {
  pageTitle: string
}

export interface AreaViewHouseAndLandWhiteLabel {
  pageTitle: string
}

export interface FilterWhiteLabel {
  blockDropdownLabel: string
  levelDropdownLabel: string
  precinctDropdownLabel: string
  stageDropdownLabel: string
  configuration: string
}

export interface LotViewWhiteLabel {
  packageListTitle: string
}

export interface ShortlistWhiteLabel {
  qrCodeScanLabel: string
  includeLabel: string
  agentLabel: string
  agentFormTitle: string
  pageTitle: string
}

export interface WhiteLabel {
  areaView: AreaViewWhiteLabel
  areaViewHouseAndLand: AreaViewHouseAndLandWhiteLabel
  filter: FilterWhiteLabel
  lotView: LotViewWhiteLabel
  shortlist: ShortlistWhiteLabel
}

interface WhiteLabelAPIData {
  remote: WhiteLabel
}

type RequestType = {
  projectName: string
}

const VERSION = 'v3'

export const whiteLabelApi = createApi({
  reducerPath: 'whiteLabelApi',
  baseQuery: baseQueryInterceptor,
  endpoints: (builder) => ({
    getWhiteLabels: builder.query<ApiResponse<WhiteLabelAPIData>, RequestType>({
      query: (params: RequestType) => ({
        url: `/${VERSION}/${params.projectName}/white-labels`,
        method: 'get',
        params: {
          app: 'remote',
        },
      }),
    }),
  }),
})

export const selectFromWhiteLabelResult = ({
  data,
  isLoading,
  isError,
  status,
  error,
}: UseQueryStateResult<any, any>): {
  whiteLabelData: WhiteLabel
  isLoaded: boolean
  isError: boolean
  status: string
  errorStatus: number
} => ({
  whiteLabelData: data?.data?.remote || {},
  isLoaded: !isLoading,
  isError,
  status,
  errorStatus: error?.status,
})

export const { useGetWhiteLabelsQuery } = whiteLabelApi
