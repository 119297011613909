import {
  PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import appConfig from '@store/actionSlices/appConfig'
import bedConfig from '@store/actionSlices/bedConfig'
import blockLevel from '@store/actionSlices/blockLevel'
import firestore from '@store/actionSlices/firestore'
import houseAndLand from '@store/actionSlices/houseAndLand'
import projectIdentity from '@store/actionSlices/projectIdentity'
import shortlist from '@store/actionSlices/shortlist'
import statusIndicator from '@store/actionSlices/statusIndicator'
import token from '@store/actionSlices/token'
import user from '@store/actionSlices/user'
import whiteLabel from '@store/actionSlices/whiteLabel'

import { userApi } from '@api/authentication'
import { bedConfigApi } from '@api/bed-config'
import { brochureApi } from '@api/brochure'
import { buildingApi } from '@api/building'
import { configApi } from '@api/config'
import { externalLinksApi } from '@api/external-links'
import { externalViewsApi } from '@api/external-views'
import { floorPlateGalleryApi } from '@api/floor-plate-gallery'
import { galleryApi } from '@api/gallery'
import { houseAndLandApi } from '@api/houseAndLand'
import { interactiveMapApi } from '@api/interactive-map'
import { interactivePlanApi } from '@api/interactive-plan'
import { locationApi } from '@api/location'
import { masterKeyApi } from '@api/master-key'
import { panoramicApi } from '@api/panoramic'
import { projectListApi } from '@api/project-list'
import { shortlistedApi } from '@api/shortlisted'
import { teamApi } from '@api/team'
import { unitGalleryApi } from '@api/unit-gallery'
import { videoGalleryApi } from '@api/video-gallery'
import { visionApi } from '@api/vision'
import { whiteLabelApi } from '@api/white-label'

const initialState = {}

const rootReducer = combineReducers({
  user,
  token,
  projectIdentity,
  shortlist,
  bedConfig,
  blockLevel,
  statusIndicator,
  appConfig,
  houseAndLand,
  firestore,
  whiteLabel,
  [userApi.reducerPath]: userApi.reducer,
  [buildingApi.reducerPath]: buildingApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [shortlistedApi.reducerPath]: shortlistedApi.reducer,
  [bedConfigApi.reducerPath]: bedConfigApi.reducer,
  [panoramicApi.reducerPath]: panoramicApi.reducer,
  [interactivePlanApi.reducerPath]: interactivePlanApi.reducer,
  [videoGalleryApi.reducerPath]: videoGalleryApi.reducer,
  [externalViewsApi.reducerPath]: externalViewsApi.reducer,
  [galleryApi.reducerPath]: galleryApi.reducer,
  [brochureApi.reducerPath]: brochureApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [interactiveMapApi.reducerPath]: interactiveMapApi.reducer,
  [visionApi.reducerPath]: visionApi.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [projectListApi.reducerPath]: projectListApi.reducer,
  [floorPlateGalleryApi.reducerPath]: floorPlateGalleryApi.reducer,
  [unitGalleryApi.reducerPath]: unitGalleryApi.reducer,
  [masterKeyApi.reducerPath]: masterKeyApi.reducer,
  [houseAndLandApi.reducerPath]: houseAndLandApi.reducer,
  [externalLinksApi.reducerPath]: externalLinksApi.reducer,
  [whiteLabelApi.reducerPath]: whiteLabelApi.reducer,
  ...initialState,
})

export type RootStateType = ReturnType<typeof rootReducer>

export function setupStore(
  preloadedState?: PreloadedState<ReturnType<typeof rootReducer>>
) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        configApi.middleware,
        userApi.middleware,
        buildingApi.middleware,
        shortlistedApi.middleware,
        bedConfigApi.middleware,
        panoramicApi.middleware,
        interactivePlanApi.middleware,
        videoGalleryApi.middleware,
        externalViewsApi.middleware,
        galleryApi.middleware,
        brochureApi.middleware,
        locationApi.middleware,
        interactiveMapApi.middleware,
        teamApi.middleware,
        visionApi.middleware,
        floorPlateGalleryApi.middleware,
        unitGalleryApi.middleware,
        projectListApi.middleware,
        masterKeyApi.middleware,
        houseAndLandApi.middleware,
        externalLinksApi.middleware,
        whiteLabelApi.middleware
      ),
    preloadedState,
  })
}

const store = setupStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatchType = typeof store.dispatch

export type AppStore = ReturnType<typeof setupStore>

export default { store }
