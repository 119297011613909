import React from 'react'
import { connect } from 'react-redux'

import type { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import BlockCard from '@components/block-card'
import Container from '@components/container'
import DataHandler from '@components/data-handler'
import FILTER_INITIAL_STATE from '@components/filter/filterState'

import {
  selectFromResult as selectBuildingFromResult,
  useGetBuildingQuery,
} from '@api/building'
import {
  Polygon,
  selectFromResult as selectInteractiveFromResult,
  useGetInteractivePlanQuery,
} from '@api/interactive-plan'
import { AreaViewWhiteLabel } from '@api/white-label'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

import AreaSkeleton from './area-skeleton'
import BlockList from './block-list'

export interface AreaViewProps {
  projectIdentity: ProjectIdentity
  session: SessionMap | undefined
  areaViewWhiteLabel: AreaViewWhiteLabel
}

function AreaView({
  projectIdentity,
  session,
  areaViewWhiteLabel,
}: AreaViewProps) {
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const [areaViews, setAreaViews] = React.useState<Array<Polygon>>([])
  const [blocks, setBlocks] = React.useState<Array<string>>([])

  const interactivePayload = useGetInteractivePlanQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectInteractiveFromResult }
  )

  const buildingPayload = useGetBuildingQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectBuildingFromResult }
  )

  const updateTriggerBuilding = (state: boolean) =>
    firebaseControlQuery.update({
      [`areaView.triggerBuilding`]: state,
    })

  const handleMapClick = async (groupId: string) => {
    if (blocks.length > 1) {
      const blockId = blocks.includes(groupId) ? groupId : blocks[0]
      await firebaseControlQuery.update({
        [`building.activeBlock`]: blockId,
      })
    }
    await updateTriggerBuilding(true)
  }

  const resetBuildingMap = () =>
    firebaseControlQuery.update({
      [`building.activeBlock`]: '',
      [`building.activeLevel`]: '',
      [`building.activeUnit`]: '',
      [`building.unitGalleryIndex`]: 0,
    })

  const resetBuildingView = () =>
    firebaseControlQuery.update({
      [`building.sidePanelFolded`]: true,
      [`building.unitFilter`]: FILTER_INITIAL_STATE,
    })

  React.useEffect(() => {
    const { maps, isLoaded, isError } = interactivePayload
    if (isLoaded && !isError && Object.keys(maps).length > 0) {
      if (areaViews.length === 0) {
        if (maps.areaView?.polygons) {
          setAreaViews(maps.areaView.polygons.map((res: Polygon) => res))
        }
      }
    }
  }, [interactivePayload.isLoaded, areaViews])

  React.useEffect(() => {
    const { maps } = interactivePayload
    const { isLoaded, isError, blockOrders, isSuccess } = buildingPayload

    if (isLoaded && isSuccess && !isError) {
      if (maps?.blocks) {
        setBlocks(
          blockOrders?.length > 1
            ? blockOrders
            : Object.keys(maps?.blocks || {})
        )
      }
    }
  }, [buildingPayload, interactivePayload.isLoaded])

  React.useEffect(() => {
    if (session) {
      const {
        areaView: { triggerBuilding },
        building: { activeLevel, activeUnit },
      } = session
      if (activeLevel || activeUnit) {
        resetBuildingMap()
      }
      if (triggerBuilding) {
        setTimeout(async () => {
          await updateTriggerBuilding(false)
          await resetBuildingView()
          await firebaseControlQuery.updateRoute('level-view')
        }, 1000)
      }
    }
  }, [session])

  return (
    <Container>
      <div className="h-full w-full px-4">
        <DataHandler
          payload={{
            ...interactivePayload,
            data: areaViews && blocks ? areaViews : [],
          }}
          skeletonFrame={<AreaSkeleton />}
        >
          <div className="flex h-top-bar w-full items-end text-title font-medium text-neutralColour">
            <div className="pb-5">
              {areaViewWhiteLabel?.pageTitle || 'Buildings'}
            </div>
          </div>
          <div className="no-scrollbar h-page-body w-full overflow-auto">
            {projectIdentity.remoteInteractiveBuilding.areaView.useMatrix ? (
              <BlockList
                projectId={projectIdentity.projectId}
                handleBlockClick={(argBlock: string) =>
                  handleMapClick(argBlock)
                }
              />
            ) : (
              areaViews.map((res) => (
                <BlockCard
                  key={res.groupId}
                  label={res.label || res.groupId}
                  handleClick={() => handleMapClick(res.groupId)}
                />
              ))
            )}
          </div>
        </DataHandler>
      </div>
    </Container>
  )
}

export default connect(
  ({
    projectIdentity,
    firestore: { session },
    whiteLabel: { areaView: areaViewWhiteLabel },
  }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
    areaViewWhiteLabel,
  })
)(AreaView)
