import React from 'react'
import { connect } from 'react-redux'

import {
  BedConfigInterface,
  DropDownOption,
  ExtendedUnitFilterOptions,
  PriceRange,
  ProjectIdentity,
  RootStateTypeExtra,
} from '@store/types'

import ToggleSwitch from '@components/toggle-switch'
import VerticalScrollContainer from '@components/vertical-scroll-container'

import { Level } from '@api/building'
import { MappingBlockCollection } from '@api/interactive-plan'
import { FilterWhiteLabel } from '@api/white-label'

import useBlockLevelData from '@utilities/use-block-level-data'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'

import { CloseSvg } from '@svg/react'

import {
  Unit,
  UnitFilterOptionsConfigurations,
} from '@adUtilities/types/apartment'
import { MinMaxInterface } from '@adUtilities/types/common'

import DropDown from '../dropdown'
import { CheckboxInput } from '../form-controls'
import FILTER_INITIAL_STATE from './filterState'
import RangeOptions from './range-options'

interface FilterProps {
  isOpen: boolean
  toggle: (arg: boolean) => void
  firebaseUnitFilter: ExtendedUnitFilterOptions
  levels: Array<Level>
  blocks: MappingBlockCollection
  prices: PriceRange
  showPrice: boolean
  hideLevel?: boolean
  isEnvisionFilter?: boolean
  aspects: Array<string>
  unitTypes: Array<string>
  bedConfig: Array<BedConfigInterface>
  projectIdentity: ProjectIdentity
  firebaseActiveBlock: string
  firebaseActiveLevel: string
  availableStatusLabel: string
  filterWhiteLabel: FilterWhiteLabel
}
interface FilterPayload {
  [key: string]: string | boolean
}

function Filter({
  isOpen,
  toggle,
  firebaseUnitFilter,
  blocks,
  levels,
  prices,
  showPrice,
  aspects,
  unitTypes,
  bedConfig,
  projectIdentity,
  firebaseActiveBlock,
  firebaseActiveLevel,
  hideLevel,
  isEnvisionFilter,
  availableStatusLabel,
  filterWhiteLabel,
}: FilterProps) {
  const AVAILABLE_STATUS = 'available'

  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })
  useBlockLevelData({ projectIdentity })

  const [levelOptions, setLevelOptions] = React.useState<Array<DropDownOption>>(
    []
  )

  const [buildingOptions, setBuildingOptions] = React.useState<
    Array<DropDownOption>
  >([])

  const [showAvailable, setShowAvailable] = React.useState(
    firebaseUnitFilter.showAvailable
  )
  const [activeBlock, setActiveBlock] = React.useState(firebaseActiveBlock)
  const [activeLevel, setActiveLevel] = React.useState(firebaseActiveLevel)
  const [checkAnyLevel, setAnyLevel] = React.useState(false)
  const [range, setRange] = React.useState<MinMaxInterface>(
    firebaseUnitFilter.range
  )
  const [configurations, setConfigurations] =
    React.useState<UnitFilterOptionsConfigurations>(
      firebaseUnitFilter.configurations
    )
  const [firebaseAspects, setFirebaseAspects] = React.useState<Array<string>>(
    firebaseUnitFilter.aspect
  )
  const [firebaseUnitType, setFirebaseUnitType] = React.useState<string>(
    firebaseUnitFilter.unitType
  )

  const filterRef = React.useRef<HTMLDivElement>(null)

  const getLevelOptions = (block?: string) => {
    const currentBlock = block || activeBlock
    const levelList: Level[] = [...levels]
    if (showAvailable) {
      levelList.filter((lvl) =>
        lvl.data.filter(
          (unt: Unit) =>
            unt.metas.status === availableStatusLabel ||
            unt.metas.status === AVAILABLE_STATUS
        )
      )
    }
    const levelItems: Array<DropDownOption> = []
    levelList.forEach((res) => {
      if (currentBlock && blocks && Object.keys(blocks).length > 1) {
        if (res.data.find((lvl) => lvl.blockId === currentBlock)) {
          levelItems.push({
            label: res.level,
            value: res.level,
          })
        }
      } else {
        levelItems.push({
          label: res.level,
          value: res.level,
        })
      }
    })
    setLevelOptions(levelItems)

    return levelItems
  }

  const unitTypeOptions = React.useMemo(
    () =>
      unitTypes?.map((item) => ({
        label: item,
        value: item,
      })),
    [unitTypes]
  )

  const handleRangeChange = (rangeValue: { max: string; min: string }) =>
    firebaseControlQuery.update({
      [`building.unitFilter.range`]: rangeValue,
    })

  const handleAnyLevel = (checked: boolean) =>
    firebaseControlQuery.update({
      [`building.unitFilter.anyLevel`]: checked,
    })

  const handleConfigurationOnchange = (
    configs: UnitFilterOptionsConfigurations
  ) =>
    firebaseControlQuery.update({
      [`building.unitFilter.configurations`]: configs,
    })

  const handleAspectOnchange = (value: string) => {
    if (firebaseAspects.includes(value)) {
      firebaseControlQuery.update({
        [`building.unitFilter.aspect`]: firebaseAspects.filter(
          (item) => item !== value
        ),
      })
    } else {
      firebaseControlQuery.update({
        [`building.unitFilter.aspect`]: [...firebaseAspects, value],
      })
    }
  }

  const handleUnitTypeOnchange = (value: string) =>
    firebaseControlQuery.update({
      [`building.unitFilter.unitType`]: value,
    })

  const handleShowAvailable = (checked: boolean) =>
    firebaseControlQuery.update({
      [`building.unitFilter.showAvailable`]: checked,
    })

  const applyFilter = () => {
    const filterPayload: FilterPayload = {
      [`building.unitFilter.apply`]: true,
      [`building.unitFilter.anyLevel`]: false,
    }
    if (buildingOptions.length > 1) {
      filterPayload['building.activeBlock'] = activeBlock
    }
    if (!hideLevel) {
      filterPayload['building.activeLevel'] = activeLevel
    }

    if (isEnvisionFilter) {
      filterPayload['envisionVR.activeBuilding'] = activeBlock
    }
    firebaseControlQuery.update(filterPayload)
    toggle(false)
  }

  const resetFilter = async () => {
    firebaseControlQuery.update({
      [`building.unitFilter`]: FILTER_INITIAL_STATE,
    })
  }

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      filterRef.current &&
      !filterRef.current.contains(event.target as Node) &&
      isOpen
    ) {
      toggle(false)
    }
  }

  React.useEffect(() => {
    if (blocks) {
      setBuildingOptions(
        Object.keys(blocks)?.map((item) => ({
          label: item,
          value: item,
        }))
      )
    }
  }, [blocks])

  React.useEffect(() => {
    getLevelOptions()
  }, [levels])

  React.useEffect(() => {
    setConfigurations(firebaseUnitFilter.configurations)
    setFirebaseAspects(firebaseUnitFilter.aspect)
    setFirebaseUnitType(firebaseUnitFilter.unitType)
    setRange(firebaseUnitFilter.range)
    setShowAvailable(firebaseUnitFilter.showAvailable)
    setAnyLevel(false)
  }, [firebaseUnitFilter])

  React.useEffect(() => {
    if (isOpen && firebaseUnitFilter.apply) {
      firebaseControlQuery.update({
        [`building.unitFilter.apply`]: false,
      })
    }
  }, [firebaseUnitFilter, activeLevel, activeBlock])

  React.useEffect(() => {
    setActiveBlock(firebaseActiveBlock || Object.keys(blocks ?? {})?.[0])
  }, [firebaseActiveBlock, blocks])

  React.useEffect(() => {
    setActiveLevel(firebaseActiveLevel)
  }, [firebaseActiveLevel])

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.addEventListener('click', handleOutsideClick, false)
    }, 0)
    return () => {
      clearTimeout(timeoutId)
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen])

  return (
    <div
      ref={filterRef}
      className={`fixed inset-y-0 right-0 z-50 h-screen w-[20rem] rounded-bl-lg rounded-tl-lg bg-secondaryColour p-5 shadow-standard ipad-pro:w-[25rem] ipad-mini-land:w-[25rem] ${
        isOpen ? '' : 'translate-x-full'
      } transition-transform duration-500`}
    >
      <div className="flex h-screen flex-col text-mainColour">
        <div className="mt-3">
          <div className="flex items-center justify-between">
            <h2 className="text-title font-medium">Filters</h2>

            <button
              className="cursor-pointer"
              type="button"
              onClick={() => {
                toggle(false)
              }}
            >
              <CloseSvg className="mr-2 h-4 w-4" strokeColor="currentColor" />
            </button>
          </div>
          <button
            className="text-xl font-normal text-[#808080] underline opacity-60"
            type="button"
            onClick={resetFilter}
          >
            Reset filters
          </button>
        </div>

        <VerticalScrollContainer className="mt-5 flex-1 overflow-y-auto">
          <div className="flex flex-col gap-5">
            {buildingOptions.length > 1 && (
              <div className="flex flex-col gap-2.5">
                <span className="text-default font-medium uppercase">
                  {filterWhiteLabel?.blockDropdownLabel || 'Building'}
                </span>
                <DropDown
                  onSelect={(value) => {
                    const [firstLevel] = getLevelOptions(value)
                    setActiveBlock(value)
                    setActiveLevel(firstLevel.value)
                  }}
                  items={buildingOptions}
                  value={activeBlock}
                />
              </div>
            )}

            {levelOptions.length > 0 && !hideLevel ? (
              <div className="flex flex-col gap-2.5">
                <span className="text-default font-medium uppercase">
                  {filterWhiteLabel?.levelDropdownLabel || 'Level'}
                </span>
                <DropDown
                  onSelect={(value) => {
                    setActiveLevel(value)
                  }}
                  disabled={checkAnyLevel}
                  items={levelOptions}
                  value={activeLevel}
                  className={checkAnyLevel ? 'opacity-60' : ''}
                />

                <div className="inline-flex hidden items-center gap-3">
                  <ToggleSwitch
                    key="offlineToggleSwitch"
                    id="offlineToggleSwitch"
                    onChangeCallback={(e) => {
                      handleAnyLevel(e.target.checked)
                    }}
                    initialState={checkAnyLevel}
                    onColor="bg-mainColour"
                    offColor="bg-grayColour"
                  />
                  <span className="text-xl text-mainColour">Any Level</span>
                </div>
              </div>
            ) : (
              ''
            )}

            {showPrice && (
              <RangeOptions
                field="price"
                options={prices}
                range={range}
                handleChange={handleRangeChange}
              />
            )}

            {Object.keys(bedConfig).length > 0 && (
              <span className="text-default font-medium uppercase">
                {filterWhiteLabel?.configuration || 'Configuration'}
              </span>
            )}

            {Object.entries(bedConfig)?.map(([field, value]) => (
              <RangeOptions
                field={field}
                key={field}
                options={value as unknown as Array<string | number>}
                range={
                  configurations[field as keyof UnitFilterOptionsConfigurations]
                }
                handleChange={(configValue: MinMaxInterface) => {
                  handleConfigurationOnchange({
                    ...configurations,
                    [field as keyof UnitFilterOptionsConfigurations]:
                      configValue,
                  })
                }}
              />
            ))}

            {unitTypeOptions.length > 0 && (
              <div className="flex flex-col gap-2.5">
                <span className="text-default font-medium uppercase">
                  Unit Types
                </span>
                <DropDown
                  onSelect={handleUnitTypeOnchange}
                  items={[{ value: 'Any', label: 'Any' }, ...unitTypeOptions]}
                  value={firebaseUnitType}
                />
              </div>
            )}

            {aspects && aspects.length > 0 && (
              <div className="flex flex-col gap-2.5">
                <span className="text-default font-medium uppercase">
                  Aspect
                </span>
                <div className="grid-cols-auto grid gap-2 md:grid-cols-[repeat(auto-fit,minmax(90px,1fr))]">
                  {aspects?.map((aspectValue: string) => (
                    <button
                      type="button"
                      key={aspectValue}
                      onClick={() => {
                        handleAspectOnchange(aspectValue.toLowerCase())
                      }}
                      className={`truncate rounded-lg px-2 py-2 text-center text-default font-medium ${
                        firebaseAspects.includes(aspectValue.toLowerCase())
                          ? 'bg-gradient text-white'
                          : 'bg-neutralColour'
                      }`}
                    >
                      {aspectValue}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </VerticalScrollContainer>

        <div className="mb-8 mt-4">
          <div className="flex items-center gap-2">
            <CheckboxInput
              initialState={showAvailable}
              onChangeCallback={(e) => {
                handleShowAvailable(e.target.checked)
              }}
              onColor="bg-mainColour"
              offColor="bg-neutralColour"
              id="availableResidence"
            />
            <span className="text-xl">Only show available residences</span>
          </div>
          <div className="mt-2">
            <button
              type="button"
              onClick={applyFilter}
              disabled={firebaseUnitFilter.apply}
              className={`w-full rounded-lg bg-gradient p-4 text-center text-subHeading font-medium text-white shadow-standard ${
                firebaseUnitFilter.apply
                  ? 'cursor-not-allowed opacity-40'
                  : 'opacity-100'
              }`}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    projectIdentity: {
      prices,
      showPrice,
      bedConfig,
      aspects,
      unitTypes,
      statusLabels,
    },
    projectIdentity,
    blockLevel: { blocks, levels },
    whiteLabel: { filter: filterWhiteLabel },
  }: RootStateTypeExtra) => ({
    projectIdentity,
    prices,
    showPrice,
    bedConfig,
    aspects,
    unitTypes,
    blocks,
    levels,
    availableStatusLabel: statusLabels?.available,
    filterWhiteLabel,
  })
)(Filter)
