import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ProjectIdentity, RootStateTypeExtra } from '@store/types'

import Container from '@components/container'
import DataHandler from '@components/data-handler'
import GalleryHandler from '@components/gallery-handler'
import GallerySkeleton from '@components/gallery-skeleton'
import PlayerControl from '@components/player-control'

import {
  selectFromResult as selectFromVideoGalleryResult,
  useGetVideoGalleryQuery,
} from '@api/video-gallery'

import FirebaseControlQuery from '@firebaseUtil/firebase-control-query'
import { SessionMap } from '@firebaseUtil/types'

export interface PagePropsInterface {
  session: SessionMap | undefined
  projectIdentity: ProjectIdentity
}

function VideoGallery({ session, projectIdentity }: PagePropsInterface) {
  const history = useHistory()
  const firebaseControlQuery = FirebaseControlQuery({
    ...projectIdentity,
    sessionKey: projectIdentity.sessionId,
  })

  const videoGalleryPayload = useGetVideoGalleryQuery(
    { projectName: projectIdentity.projectId },
    { selectFromResult: selectFromVideoGalleryResult }
  )

  const [currentVideoPlayback, setCurrentVideoPlayback] =
    React.useState<string>('')

  React.useEffect(() => {
    const isVideoPlaying =
      session?.videoGallery?.playerControl.playerState === 'play'

    if (isVideoPlaying) {
      firebaseControlQuery.update({
        [`videoGallery.playerControl`]: {
          ...session?.videoGallery.playerControl,
          playerState: 'stop',
          isPlayerVisible: false,
          currentVideoPositionInSeconds: -1,
          scrubPositionInSeconds: -1,
        },
      })
    }
  }, [history])

  React.useEffect(() => {
    const routeListener = history.listen((location: Location) => {
      firebaseControlQuery.update({
        previousRoute: location.pathname,
      })
    })

    const handleBeforeUnload = () => {
      window.history.replaceState({}, '')
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      routeListener()
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [history])

  return (
    <Container>
      <DataHandler
        payload={{
          ...videoGalleryPayload,
          data: videoGalleryPayload.videoGalleryData,
        }}
        skeletonFrame={<GallerySkeleton />}
      >
        <div className="h-full w-full px-4">
          <div className="flex h-top-bar w-full items-center justify-center text-neutralColour">
            <div className="text-title font-medium">Videos</div>
          </div>
          <div className="no-scrollbar relative h-page-body w-full overflow-auto">
            <div className="no-scrollbar h-full overflow-y-auto">
              <GalleryHandler
                galleryName="videoGallery"
                galleries={videoGalleryPayload.videoGalleryData}
                gallerySession={session?.videoGallery}
                showPlayButton
                onVideoPlayback={setCurrentVideoPlayback}
                gridColumnClass="grid-cols-3 pb-28"
                previousRoute={String(session?.previousRoute)}
              />
            </div>
            <PlayerControl
              galleryName="videoGallery"
              playerSession={session?.videoGallery.playerControl}
              currentVideoPlayback={currentVideoPlayback}
            />
          </div>
        </div>
      </DataHandler>
    </Container>
  )
}

export default connect(
  ({ projectIdentity, firestore: { session } }: RootStateTypeExtra) => ({
    session,
    projectIdentity,
  })
)(VideoGallery)
